import React from 'react'
import styles from './ienotsupported.module.css'
import fonts from '../styles/fonts.module.css'

export default () => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <h2 className={fonts.heading2}>Internet Exploreria ei tueta</h2>
        <p className={fonts.basicParagraph}>
          Valitettavasti tilavarausten tekeminen ei onnistu käyttämälläsi selaimella.{' '}
          <a href={`${process.env.GATSBY_SH_URL}`} className={fonts.textLink}>
            Siirry etusivulle
          </a>
        </p>
      </div>
      <div className={styles.textContainer}>
        <h2 className={fonts.heading2}>Internet explorer stöds inte</h2>
        <p className={fonts.basicParagraph}>
          Det är tyvärr inte möjligt att boka lokaler med webbläsaren du använder.{' '}
          <a href={`${process.env.GATSBY_SH_URL}/sv`} className={fonts.textLink}>
            Gå till hemsidan
          </a>
        </p>
      </div>
      <div className={styles.textContainer}>
        <h2 className={fonts.heading2}>Internet explorer is not supported</h2>
        <p className={fonts.basicParagraph}>
          Unfortunately it is not possible to book venues with the browser you are using.{' '}
          <a href={`${process.env.GATSBY_SH_URL}/en`} className={fonts.textLink}>
            Go to front page
          </a>
        </p>
      </div>
      <div className={styles.textContainer}>
        <h2 className={fonts.heading2}>Internet Explorerit ei toetata</h2>
        <p className={fonts.basicParagraph}>
          Kahjuks ei ole teie brauseriga võimalik ruume broneerida.{' '}
          <a href={`${process.env.GATSBY_SH_URL}/et`} className={fonts.textLink}>
            Mine avalehele
          </a>
        </p>
      </div>
    </div>
  )
}
